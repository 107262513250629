<template>
  <!-- 关于我们 -->
  <div class="xx_aboutuser">
    <div class="xx_aboutuser_bg">
      <div
        class="big"
        :style="{
          backgroundImage: 'url(' + xxBg + ')'
        }"
      >
        <img :src="H5_IMG_HOST + xxBg" alt="" style="width:100%" />
      </div>
    </div>
    <ul class="xx_aboutuser_type">
      <li
        v-for="(item, idx) in gywmList"
        :key="idx"
        :class="[head_active == idx ? 'xz_type' : '']"
        @click="head_active = idx"
      >
        {{ item.gywm ? item.gywm : item.wzsm }}
      </li>
    </ul>
    <ul class="xx_aboutuser_list" v-if="gywmList.length != 0">
      <li v-html="gywmList[0].gywmHtml" v-if="head_active == 0"></li>
      <li v-html="gywmList[1].wzsmHtml" v-else></li>
    </ul>
  </div>
</template>

<script>
import { getCaseTypes, getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      sjHead: [],
      sjList: [],
      gywmList: [],
      head_active: 0,
      project: 0,
      xxBg: ''
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.gywmList = []
          that.gywmList.push(res.data.data.gywm)
          that.gywmList.push(res.data.data.wzsm)
          that.xxBg = res.data.data.jcpz.image
        }
      })
      that.project = sessionStorage.getItem(that.changeData() + 'project')
      getCaseTypes(that.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          that.sjHead = res.data.data
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.xx_aboutuser {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .xx_aboutuser_bg {
    overflow: hidden;
    position: relative;
    .big {
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: 70% center;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 5rem;
      animation: scaleAnim 5s ease forwards;
      img {
        width: 100%;
        opacity: 0;
      }
    }
  }
  .xx_aboutuser_type {
    width: 15rem;
    background-color: #fff;
    margin: 3% auto;
    border-bottom: 1px solid #d4237a;
    text-align: center;
    display: flex;
    justify-content: center;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      font-size: 0.18rem;
      color: #333333;
      position: relative;
      text-align: center;
      width: 1.5rem;
      padding: 0.3rem 0.15rem;
      cursor: pointer;
    }
    li:hover,
    .xz_type {
      background-color: #d4237a;
      color: #fff;
    }
  }
  .xx_aboutuser_list {
    width: 15rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 3%;
    strong {
      font-weight: 600;
      font-size: large;
    }
    li {
      padding: 0.2rem;
      display: inline-block;
      font-size: 0.18rem;
      line-height: 2;
      vertical-align: middle;
      width: calc(100% - 0.4rem);
      background-color: #fff;
    }
  }
  .all-pagination {
    padding-bottom: 35px;
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
